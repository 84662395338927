( () => {
	let observer: IntersectionObserver | null = null;
	if ( 'IntersectionObserver' in window ) {
		observer = new IntersectionObserver( ( entries ) => {
			for ( const entry of entries ) {
				if ( !( entry.target instanceof HTMLElement ) ) {
					continue;
				}
				if ( entry.isIntersecting && !entry.target.classList.contains( 'is-intersecting' ) ) {
					entry.target.classList.add( 'is-intersecting' );
				}
			}
		} );
	}

	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		const elements = document.querySelectorAll( '[animate-when-coming-into-view]' );

		if ( !elements || 0 > elements.length ) {
			return;
		}

		init = () => { /* noop */ };

		elements.forEach( ( el ) => {
			if ( observer ) {
				observer.observe( el );

				return;
			}
		} );
	};

	document.addEventListener( 'readystatechange', init, false );

	init();
} )();
