/*
 * Mr Window Watcher 🖥👀
 *
 * Airclad has some fancy pants stuff going on depending on window events.
 *
 * -----
 *
 * Elements we need:
 *
 * 1: The body, because state modifier classes are added to it [see A & B]
 *
 * 2: The footer, because we need to know it's height [see B]
 *
 * -----
 *
 * Events we listen for:
 *
 * A: Scroll Event
 *
 * The site header has different states depening on the scroll position:
 *
 * - if the user has scrolled down, the body gets an 'is-scrolled' class;
 *   this gives the site header a filled background, among other stuff.
 *
 * - if a user scrolls back, the body gets an 'is-scrolled-up' class;
 *   this show an extra row of site header links on mobile.
 *
 * B: Resize Event
 *
 * The site has a revealing footer effect, which uses a fixed site footer
 * behind the main content, and a bottom padding on the body equal to the
 * height of the footer. This size changes on resize, so we listen for it.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-window-watcher', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.prevScrollPosY = 0;
			this.scrollDirectionY = 'down';
			this.scrollDirectionThreshold = 50;

			this.checkBodyScroll();
			this.checkFooterHeight();
		}

		// [A]
		checkBodyScroll() {
			const scrollPosY = window.pageYOffset;
			const states = {
				nearTop: 'is-near-top',
				scrolledUp: 'is-scrolled-up',
			};

			// Set scroll direction
			if ( scrollPosY < this.prevScrollPosY - this.scrollDirectionThreshold ) {
				this.scrollDirectionY = 'up';
				this.prevScrollPosY = scrollPosY;

			} else if ( 50 > scrollPosY || scrollPosY > this.prevScrollPosY + this.scrollDirectionThreshold ) {
				this.scrollDirectionY = 'down';
				this.prevScrollPosY = scrollPosY;

			}
			switch ( this.scrollDirectionY ) {
				case 'up':
					document.body.classList.add( states.scrolledUp );
					break;

				case 'down':
					document.body.classList.remove( states.scrolledUp );

			}

			// Check if scroll is near top
			if ( 50 > scrollPosY ) {
				document.body.classList.add( states.nearTop );
				document.body.classList.remove( states.scrolledUp );

			} else {
				document.body.classList.remove( states.nearTop );

			}
		}

		// [B]
		checkFooterHeight() {
			const footerEl = document.querySelector( '.js-footer' );

			if ( !footerEl ) {
				return;
			}

			const footerHeight = footerEl.offsetHeight;

			if ( isNaN( footerHeight ) || window.innerHeight - 25 < footerHeight ) {
				footerEl.style.position = 'static';
				document.body.style.paddingBottom = '0px';

			} else {
				footerEl.style.position = 'fixed';
				document.body.style.paddingBottom = `${footerHeight}px`;

			}
		}

		bind() {
			if ( 'undefined' === typeof requestAnimationFrame ) {
				return;
			}

			// [A]
			this.on( 'scroll', () => {
				document.body.classList.add( 'is-interacted' );

				window.requestAnimationFrame( () => {
					this.checkBodyScroll( window.pageYOffset );
				} );

			}, window, {
				passive: true,
			} );

			// [B]
			this.on( 'resize', () => {
				window.requestAnimationFrame( () => {
					this.checkFooterHeight();
				} );
			}, window, {
				passive: true,
			} );
		}
	},
} );
