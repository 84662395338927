/*
 * Mr Slideshow 🏂
 *
 * Creates a slideshow with navigation and touch support.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-slideshow:current', {
				current: to,
			} );

			this.render();
		}

		get current() {
			return parseFloat( this.el.getAttribute( 'current' ) );
		}

		set current( to ) {
			const parsed = parseFloat( to );

			if ( Number.isNaN( parsed ) ) {
				console.warn( `Could not set ${'current'} to ${to}` );
				this.el.removeAttribute( 'current' );
			} else {
				this.el.setAttribute( 'current', parsed );
			}

			this.currentChangedCallback();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();
			this.calculateOffset(); // [1]

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.items.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = this.elements.items.length - 1;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = 0;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-slideshow-item' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-slideshow-item' ) );
			this.elements.images = Array.from( this.el.querySelectorAll( '.js-slideshow-item img' ) );
			this.elements.parent = this.el.querySelector( '.js-slideshow-parent' );
			this.elements.track = this.el.querySelector( '.js-slideshow-track' );

			this.emit( 'mr-slideshow:current', {
				current: this.current,
			} );

			// [1]
			// start offset out with a guess that'll work for most screens
			this.offset = window.innerWidth * 0.125;

			// Turn off drag on images
			this.elements.images.forEach( ( image ) => {
				image.draggable = false;
			} );

			this.start();
		}

		bind() {
			this.on( 'keydown', ( e ) => {
				if ( 37 === e.keyCode ) {
					this.previous();
				} else if ( 39 === e.keyCode ) {
					this.next();
				}
			}, window, {
				passive: true,
			} );

			// this.on('click .js-slideshow-item', (e, slideshowItem) => {
			// 	let index = 0;
			// 	this.elements.items.some((item, i) => {
			// 		if (item === slideshowItem) {
			// 			index = i;
			// 			return true;
			// 		}
			// 	});
			//
			// 	if (index !== this.current) {
			// 		this.current = index;
			// 		return;
			// 	}
			//
			// 	const clickX = e.clientX;
			// 	const boundingBox = slideshowItem.getBoundingClientRect();
			// 	const clickWithinRightHalf = ((clickX - boundingBox.left) / boundingBox.width) > 0.5;
			//
			// 	if (clickWithinRightHalf) {
			// 		this.next();
			// 	} else {
			// 		this.previous();
			// 	}
			// });

			this.on( 'click .js-slideshow-button', () => {
				if ( this.current === ( this.elements.items.length - 1 ) ) {
					this.current = 0;
				} else {
					this.next();
				}
			} );

			// Start: On touch Events
			const touch = {
				startX: 0,
				threshold: 100,
				tresholdTime: 100,
				startTime: 0,
			};

			this.on( 'touchstart', ( startEvent ) => {
				touch.startX = startEvent.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();

				// Turn off transition
				this.el.classList.add( 'is-dragged' );

				this.on( 'touchmove', ( moveEvent ) => {
					const distX = moveEvent.changedTouches[0].screenX - touch.startX;
					this.render( distX );
				} );
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;

				// Turn on transition
				this.el.classList.remove( 'is-dragged' );

				if ( new Date().getTime() - touch.startTime >= touch.tresholdTime &&
					Math.abs( distX ) >= touch.threshold ) {
					if ( 0 > distX ) {
						this.next();
					} else {
						this.previous();
					}
				} else {
					this.render();
				}

				this.off( 'touchmove' );
			} );

			// Start: On Mouse Events
			const mouse = {
				startX: 0,
				threshold: 100,
				tresholdTime: 100,
				startTime: 0,
			};

			this.on( 'mousedown', ( downEvent ) => {
				mouse.startX = downEvent.pageX;
				mouse.startTime = new Date().getTime();

				// Turn off transition
				this.el.classList.add( 'is-dragged' );

				this.on( 'mousemove', ( moveEvent ) => {
					const distX = moveEvent.pageX - mouse.startX;
					this.render( distX );
				} );

				this.on( 'mouseup', ( upEvent ) => {
					const distX = upEvent.pageX - mouse.startX;

					// Turn on transition
					this.el.classList.remove( 'is-dragged' );

					if ( new Date().getTime() - mouse.startTime >= mouse.tresholdTime &&
						Math.abs( distX ) >= mouse.threshold ) {
						if ( 0 > distX ) {
							this.next();
						} else {
							this.previous();
						}
					} else {
						this.render();
					}

					// Turn off listeners
					this.off( 'mousemove' );
					this.off( 'mouseup', document.body );
				}, document.body );
			} );

			this.on( 'resize', () => {
				this.current = 0;
				this.calculateOffset();
			}, window, {
				passive: true,
			} );
		}

		render( moveTo = 0 ) {
			const position = this.elements.items[this.current].offsetLeft - this.offset - moveTo;
			const transform = `translateX(${Math.round( position ) * -1}px)`;

			this.scroll( transform );

			if ( this.current === ( this.elements.items.length - 1 ) ) {
				this.elements.parent.classList.add( 'is-at-end' );
			} else {
				this.elements.parent.classList.remove( 'is-at-end' );
			}
		}

		/*
		 * [1]
		 *
		 * This slideshow uses an offset, always showing a bit of
		 * the next and previous items. This offset depends on the
		 * window width; it follows the wrapper styles.
		 *
		 * (we need to calc, because we can't now for sure that
		 * track.style.paddingleft returns a pixel value)
		 *
		 */
		calculateOffset() {
			const windowWidth = window.innerWidth;

			if ( 768 > windowWidth ) {
				// this.offset = window.innerWidth * 0.05;
				this.offset = 0;
			} else if ( 768 <= windowWidth && 1680 > windowWidth ) {
				this.offset = window.innerWidth * 0.125;
			} else if ( 1680 <= windowWidth ) {
				this.offset = ( window.innerWidth - 1260 ) * 0.5;
			}
		}

		scroll( transform ) {
			if ( !transform ) {
				return;
			}

			Object.assign( this.elements.track.style, {
				transform: transform,
				webkitTransform: transform,
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
