/*
 * Mr Point of View Gallery 🏟
 *
 * The Point of View module shows an image with clickable hotspots.
 * These hotspots replace the current image with a new one,
 * and reorganizes the other hotspots accordingly.
 *
 * Main use will be a series of 3D renders, allowing the user to
 * discover different angles and close-ups by clicking through
 * the hotpots.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-point-of-view-gallery', {
	attributes: [
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-point-of-view-gallery:current', {
				current: to,
			} );

			this.render();
		}

		init() {
			this.elements = {};

			this.elements.hotspots = Array.from( this.el.querySelectorAll( '.js-point-of-view-gallery-hotspot' ) );
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-point-of-view-gallery-item' ) );
			this.elements.track = this.el.querySelector( '.js-point-of-view-gallery-track' );

			this.firstRun = true;
		}

		bind() {
			if ( this.elements.hotspots.length ) {
				this.elements.hotspots.forEach( ( hotspot ) => {
					this.on( 'click', ( e ) => {
						e.preventDefault();

						const index = hotspot.getAttribute( 'hotspot-target' );

						if ( index === this.current ) {
							return;
						}

						this.current = index;
					}, hotspot );
				} );
			}
		}

		showHotspots() {
			const currentHotspots = Array.from( this.el.querySelectorAll( `.js-point-of-view-gallery-hotspot[hotspot-index="${this.current}"]` ) );

			currentHotspots.forEach( ( hotspot ) => {
				hotspot.classList.add( 'is-showing' );

				if ( hotspot.getAttribute( 'hotspot-target' ) === this.current.toString() ) {
					hotspot.classList.add( 'is-active' );
				}
			} );
		}

		blurAllHotspots() {
			if ( !this.elements.hotspots.length ) {
				return;
			}

			this.elements.hotspots.forEach( ( hotspot ) => {
				hotspot.classList.remove( 'is-active' );
				hotspot.classList.remove( 'is-showing' );
			} );
		}

		render() {
			if ( this.firstRun ) {
				this.firstRun = false;
				this.showHotspots();

				return;
			}

			if ( !this.elements.items.length ) {
				return;
			}

			this.blurAllHotspots();

			const active = this.elements.items[this.current];

			if ( !active || !this.elements.track ) {
				return;
			}

			this.elements.track.classList.add( 'is-hidden' );

			const transform = `translateX(-${Math.round( active.offsetLeft )}px)`;

			setTimeout( () => {
				Object.assign( this.elements.track.style, {
					transform: transform,
					webkitTransform: transform,
				} );
			}, 160 );

			setTimeout( () => {
				this.elements.track.classList.remove( 'is-hidden' );
			}, 320 );

			setTimeout( () => {
				this.showHotspots();
			}, 480 );
		}
	},
} );
