/*
 * Mr Carousel 🏂
 *
 * Slideshow for the landing page
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-carousel', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-carousel:current', {
				current: to,
			} );

			this.render();
		}

		get current() {
			return parseFloat( this.el.getAttribute( 'current' ) );
		}

		set current( to ) {
			const parsed = parseFloat( to );

			if ( Number.isNaN( parsed ) ) {
				console.warn( `Could not set ${'current'} to ${to}` );
				this.el.removeAttribute( 'current' );
			} else {
				this.el.setAttribute( 'current', parsed );
			}

			this.currentChangedCallback( to );
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.startInterval();
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.slides.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = 0;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = this.elements.slides.length - 1;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-carousel-slide' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.buttons = Array.from( this.el.querySelectorAll( '.js-carousel-button' ) );
			this.elements.media = Array.from( this.el.querySelectorAll( '.js-carousel-slide-media' ) );
			this.elements.slides = Array.from( this.el.querySelectorAll( '.js-carousel-slide' ) );

			this.emit( 'mr-carousel:current', {
				current: this.current,
			} );

			// Turn off drag on images
			this.elements.media.forEach( ( image ) => {
				image.draggable = false;
			} );

			if ( 'IntersectionObserver' in window ) {
				const observer = new IntersectionObserver( ( entries ) => {
					for ( const entry of entries ) {
						if ( !( entry.target instanceof HTMLElement ) ) {
							continue;
						}

						if ( entry.isIntersecting ) {
							// stop observing
							observer.unobserve( this.el );

							// Wait 1 second
							// If you scroll slow you won't even notice because the animation happens underneath it
							setTimeout( () => {
								// start animating
								this.start();
								// make sure css animates in title
								this.el.classList.remove( 'is-observing' );
							}, 1024 );
						}
					}
				} );

				observer.observe( this.el );
				this.el.classList.add( 'is-observing' );
			} else {
				// no intersection observer, just start it
				this.start();
			}
		}

		bind() {
			this.on( 'keydown', ( e ) => {
				if ( 37 === e.keyCode ) {
					this.previous();

					if ( this.looper ) {
						clearInterval( this.looper );
						this.startInterval();
					}
				} else if ( 39 === e.keyCode ) {
					this.next();

					if ( this.looper ) {
						clearInterval( this.looper );
						this.startInterval();
					}
				}
			}, window, {
				passive: true,
			} );

			this.on( 'click .js-carousel-button', ( e, target ) => {
				e.preventDefault();

				const index = parseFloat( target.getAttribute( 'index' ) );

				if ( null === index || isNaN( index ) ) {
					return;
				}

				this.current = index;

				if ( this.looper ) {
					clearInterval( this.looper );
					this.startInterval();
				}
			} );

			this.on( 'resize', () => {
				this.current = 0;

				if ( this.looper ) {
					clearInterval( this.looper );
					this.startInterval();
				}
			}, window, {
				passive: true,
			} );
		}

		render() {
			const oldActiveSlide = this.el.querySelector( '.js-carousel-slide.is-active' );
			const newActiveSlide = this.elements.slides[this.current];

			const oldActiveButton = this.el.querySelector( '.js-carousel-button.is-active' );
			const newActiveButton = this.elements.buttons[this.current];

			const oldActiveVideoId = this.el.querySelector( '.js-carousel-slide.is-active' ).getAttribute( 'video-id' );
			const newActiveVideoId = this.elements.slides[this.current].getAttribute( 'video-id' );

			if ( !newActiveSlide || !newActiveButton ) {
				return;
			}

			if (
				oldActiveSlide &&
				oldActiveSlide.classList &&
				oldActiveSlide.classList.contains( 'is-active' ) &&
				( oldActiveSlide !== newActiveSlide )
			) {
				this.stopPlayback( oldActiveVideoId );
				oldActiveSlide.classList.remove( 'is-active' );
			}

			this.startPlayback( newActiveVideoId );
			newActiveSlide.classList.add( 'is-active' );

			if ( oldActiveButton && oldActiveButton.classList && oldActiveButton.classList.contains( 'is-active' ) ) {
				oldActiveButton.classList.remove( 'is-active' );
			}

			newActiveButton.classList.add( 'is-active' );
		}

		startInterval() {
			if ( !this.auto || 0 === this.auto || isNaN( this.auto ) ) {
				return;
			}

			this.looper = setInterval( () => {
				this.next();
			}, this.auto );
		}

		destroy() {
			this.stop();
			super.destroy();
		}

		startPlayback( videoId ) {
			if ( !videoId ) {
				return;
			}

			window.requestAnimationFrame( () => {
				this.el.dispatchEvent( new CustomEvent( 'mr-carousel:startPlayback', {
					bubbles: true,
					cancelable: true,
					detail: {
						videoId: videoId,
					},
				} ) );
			} );
		}

		stopPlayback( videoId ) {
			if ( !videoId ) {
				return;
			}

			window.requestAnimationFrame( () => {
				this.el.dispatchEvent( new CustomEvent( 'mr-carousel:stopPlayback', {
					bubbles: true,
					cancelable: true,
					detail: {
						videoId: videoId,
					},
				} ) );
			} );
		}
	},
} );
