/*
 * Mr Story 🤓
 *
 * Element to animate the slides from one to another.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

defineCustomElement( 'mr-story', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			if ( !( 'IntersectionObserver' in window ) ) {
				// No IntersectionObserver, no fun.
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		currentChangedCallback() {
			this.render();
		}

		get current() {
			return parseFloat( this.el.getAttribute( 'current' ) );
		}

		set current( to ) {
			const parsed = parseFloat( to );

			if ( Number.isNaN( parsed ) ) {
				console.warn( `Could not set ${'current'} to ${to}` );
				this.el.removeAttribute( 'current' );
			} else {
				this.el.setAttribute( 'current', parsed );
			}

			this.currentChangedCallback();
		}

		init() {
			this.elements = {};
			this.elements.slides = Array.from( this.el.querySelectorAll( '.js-story__slide' ) );
			this.elements.links = Array.from( this.el.querySelectorAll( '.js-story__link' ) );
			this.elements.footer_toggles = Array.from( this.el.querySelectorAll( '.js-story__footer-toggle' ) );

			document.body.classList.add( 'has-story-resolved' );

			this.current = 0;
		}

		bind() {
			if ( this.elements.links.length ) {
				this.elements.links.forEach( ( link ) => {
					this.on( 'click', () => {
						this.current = this.elements.links.indexOf( link );
					}, link );
				} );
			}

			if ( 0 < this.elements.slides.length ) {
				// Set Intersection Observer
				const options = {
					threshold: 0.8,
				};

				const observer = new IntersectionObserver( ( entries ) => {
					for ( let i = 0; i < entries.length; i += 1 ) {
						const entry = entries[i];
						if ( entry.isIntersecting ) {
							this.current = this.elements.slides.indexOf( entry.target );
						}
					}
				}, options );


				for ( let i = 0; i < this.elements.slides.length; i += 1 ) {
					observer.observe( this.elements.slides[i] );
				}
			}

			if ( 0 < this.elements.footer_toggles.length ) {
				// turn off hint if read more is used once
				this.elements.footer_toggles.forEach( ( footer_toggle ) => {
					this.on( 'click', ( e ) => {
						// this is the slide section
						const parent = e.target.parentNode;

						if ( parent.classList.contains( 'is-showing-hint' ) ) {
							parent.classList.remove( 'is-showing-hint' );
						}
					}, footer_toggle );
				} );
			}
		}

		render() {
			if ( 0 > this.current || this.current >= this.elements.slides.length ) {
				// not a real slide
				return;
			}

			if ( this.elements.links.length ) {
				for ( let i = 0; i < this.elements.links.length; i++ ) {
					this.elements.links[i].classList.remove( 'is-active' );
				}
			}

			// Toggle all read more off
			if ( this.elements.footer_toggles.length ) {
				for ( let i = 0; i < this.elements.footer_toggles.length; i++ ) {
					this.elements.footer_toggles[i].checked = false;
				}
			}

			for ( let i = 0; i < this.elements.slides.length; i++ ) {
				this.elements.slides[i].classList.remove( 'is-current' );
			}

			this.elements.slides[this.current].classList.add( 'is-current' );
			this.elements.links[this.current].classList.add( 'is-active' );

			const onComplete = () => {
				this.elements.slides[this.current].classList.add( 'is-animating' );
			};

			const slideVideo = this.elements.slides[this.current].querySelector( '.js-story__video' );
			if ( slideVideo && !this.elements.slides[this.current].classList.contains( 'is-animating-video' ) ) {
				this.elements.slides[this.current].classList.add( 'is-animating-video' );

				setTimeout( () => {
					this.on( 'ended', () => {
						onComplete();
					}, slideVideo );

					slideVideo.currentTime = 0;
					const playPromise = slideVideo.play();

					if ( playPromise ) {
						playPromise.then( () => {
							slideVideo.removeAttribute( 'controls' );
						} ).catch( ( err ) => {
							console.log( 'Story : start playback for video in slide - failed' );

							if ( slideVideo.src ) {
								console.log( 'Story : src - ' + slideVideo.src );
							}

							if ( slideVideo.error ) {
								console.log( 'Story : err - ' + slideVideo.error.code + '; details :', slideVideo.error.message );
							} else {
								// Devices with battery or data saving mode enabled might not allow playing this video
								console.log( 'Story : unable / not allowed to play video' );
							}

							onComplete();

							if ( isUnexpectedError( err ) ) {
								bugsnagClient.notify( err );
							} else {
								console.warn( err );
							}
						} );
					} else {
						// IE play doesnt return a promise, just remove controlls anyway
						slideVideo.removeAttribute( 'controls' );
					}
				}, 96 );

				return;
			}

			setTimeout( () => {
				onComplete();
			}, 280 );
		}
	},
} );

// Some errors do not indicate a code bug.
// They are the result of expected device settings, user interactions or a broken video.
// We do not report this, but output as console.warn
const isUnexpectedError = ( err ) => {
	if ( err && 'AbortError' === err.name ) {
		return false;
	}

	if ( err && 'PlayInterrupted' === err.name ) {
		return false;
	}

	if ( err && 'NotAllowedError' === err.name ) {
		return false;
	}

	if ( err && 'NotSupportedError' === err.name ) {
		return false;
	}

	return true;
};
