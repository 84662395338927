/*
 * Mr Video Controls 🎮
 *
 * Sends out and listens to html5 media events of media custom elements (Mr Video)
 *
 * It supports:
 *
 * [1] play / pause          (togglePlayback)
 * [2] mute / unmute         (toggleMute)
 * [3] fullscreen request    (toggleFullscreen)
 * [4] jump to time position (jump)
 * [5] scrubbing             (also jump, but with sauce)
 * [6] jumping to waypoints
 * [7] Auto show / hide controls
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video-controls', {
	attributes: [
		{
			attribute: 'video-id',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.fullscreen = this.el.querySelector( '.js-video-controls-fullscreen' );
			this.elements.mute = this.el.querySelector( '.js-video-controls-mute' );
			this.elements.range = this.el.querySelector( '.js-video-controls-range' );
			this.elements.play = this.el.querySelector( '.js-video-controls-play' );
			this.elements.played = this.el.querySelector( '.js-video-controls-played' );
			this.elements.waypoints = Array.from( this.el.querySelectorAll( '.js-video-controls-waypoint' ) );

			this.continuePlaying = false;
			this.playing = false;
			this.rangeFocus = false;
		}

		bind() {
			// [1]
			if ( this.elements.play ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'togglePlayback' );
				}, this.elements.play );
			}

			// [2]
			if ( this.elements.mute ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'toggleMute' );
				}, this.elements.mute );
			}

			// [3]
			if ( this.elements.fullscreen ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'toggleFullscreen' );
				}, this.elements.fullscreen );
			}

			if ( this.elements.range ) {
				// [4]
				this.on( 'click', ( e ) => {
					if ( !this.videoId || !this.elements.range ) {
						this.unbind();

						return;
					}

					this.jump( e.clientX );
				}, this.elements.range );

				// [5]
				this.on( 'mousedown', ( e ) => {
					if ( !this.elements.range ) {
						return;
					}

					e.preventDefault();
					e.stopPropagation();

					if ( this.playing ) {
						this.continuePlaying = true;
						this.dispatch( 'togglePlayback' );
					} else {
						this.continuePlaying = false;
					}

					this.rangeFocus = true;
				}, this.elements.range );
			}

			// [5]
			this.on( 'mouseup', ( e ) => {
				if ( !this.elements.range || !this.rangeFocus ) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				if ( this.continuePlaying ) {
					this.dispatch( 'togglePlayback' );
				}

				this.jump( e.clientX );

				this.rangeFocus = false;
			}, window );

			// [5]
			this.on( 'mousemove', ( e ) => {
				if ( !this.elements.played || !this.elements.range || !this.rangeFocus ) {
					return;
				}

				const mouseX = e.clientX;
				const length = this.elements.range.offsetWidth;
				const offset = this.elements.range.getBoundingClientRect().left;
				const position = ( mouseX - offset ) / length;

				let scrubLocation = 0;

				if ( mouseX < offset ) {
					scrubLocation = 0;
				} else if ( mouseX >= offset && mouseX <= ( length + offset ) ) {
					scrubLocation = mouseX - offset;
				} else if ( mouseX > ( length + offset ) ) {
					scrubLocation = length;
				}

				const scrubRange = () => {
					this.elements.played.style.transform = `scaleX(${scrubLocation / length}px)`;
				};

				if ( position && !isNaN( position ) && 0 < position && scrubLocation && !isNaN( scrubLocation ) ) {
					requestAnimationFrame( scrubRange );

					this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
						bubbles: true,
						cancelable: true,
						detail: {
							position: position,
							videoId: this.videoId,
						},
					} ) );
				}
			}, window );

			// [6]
			if ( this.elements.waypoints.length ) {
				this.elements.waypoints.forEach( ( waypoint ) => {
					this.on( 'click', ( e ) => {
						if ( !this.videoId ) {
							this.unbind();

							return;
						}

						e.preventDefault();

						const position = waypoint.dataset.position;

						if ( null !== position ) {
							this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
								bubbles: true,
								cancelable: true,
								detail: {
									position: position / 100,
									videoId: this.videoId,
								},
							} ) );
						}
					}, waypoint );
				} );
			}

			// mr-video events

			// [7]
			this.on( 'mr-video:showControls', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId ) {
					return;
				}

				this.el.classList.add( 'is-showing' );
			}, window );

			// [7]
			this.on( 'mr-video:playing', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.play ) {
					return;
				}

				this.playing = true;
				this.elements.play.classList.add( 'is-toggled' );
			}, window );

			this.on( 'mr-video:paused', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.play ) {
					return;
				}

				this.playing = false;
				this.elements.play.classList.remove( 'is-toggled' );
			}, window );

			this.on( 'mr-video:unmuted', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.mute ) {
					return;
				}


				this.elements.mute.classList.remove( 'is-toggled' );
			}, window );

			this.on( 'mr-video:muted', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.mute ) {
					return;
				}

				this.elements.mute.classList.add( 'is-toggled' );
			}, window );

			this.on( 'mr-video:timeUpdate', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.played || !e.detail.currentTimePercentage ) {
					return;
				}

				this.elements.played.style.transform = `scaleX(${e.detail.currentTimePercentage})`;
			}, window );

			// [7]
			this.on( 'mr-video:focussed', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId ) {
					return;
				}

				this.el.classList.add( 'is-showing' );
			}, window );

			// [7]
			this.on( 'mr-video:unfocussed', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId ) {
					return;
				}

				this.el.classList.remove( 'is-showing' );
			}, window );
		}

		jump( mouseX ) {
			const length = this.elements.range.offsetWidth;
			const offset = this.elements.range.getBoundingClientRect().left;

			// get position relative to scrub width
			// e.g. if user clicks in exact mid of scrub
			// position will be 0.5
			const position = ( mouseX - offset ) / length;

			if ( position && !isNaN( position ) && 0 < position ) {
				this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
					bubbles: true,
					cancelable: true,
					detail: {
						position: position,
						videoId: this.videoId,
					},
				} ) );
			}
		}

		dispatch( event ) {
			this.el.dispatchEvent( new CustomEvent( `mr-video-controls:${event}`, {
				bubbles: true,
				cancelable: true,
				detail: {
					videoId: this.videoId,
				},
			} ) );
		}
	},
} );
