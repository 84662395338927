/*
 * Mr Accordion 🎹
 *
 * Needed to add a checkbox-like "uncheck" funcionality to a radio button.
 * This way we can still have a nice css-only "auto-close others" when
 * a user opens an accordion item. (you don't get that with checkboxes)
 *
 * Additionally, we update aria roles to keep this thing super accessible.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-accordion', {
	attributes: [],
	controller: class extends BaseController {
		bind() {
			const items = Array.from( this.el.querySelectorAll( '.js-accordion-item' ) );

			if ( items.length ) {
				items.forEach( ( item ) => {
					const id = item.getAttribute( 'id' );
					const content = item.querySelector( '.js-accordion-content' );
					const radio = item.querySelector( '.js-accordion-radio' );
					const trigger = item.querySelector( '.js-accordion-trigger' );

					this.on( 'click', ( e ) => {
						if ( !content || !radio ) {
							return;
						}

						e.preventDefault();

						// This makes it possible to uncheck the radio button
						radio.checked = !radio.checked;

						// Update the ARIA roles to boost accessibility.
						// Wait small amount of time to trigger css transition.
						setTimeout( () => {
							content.setAttribute( 'aria-hidden', !radio.checked );
							trigger.setAttribute( 'aria-expanded', radio.checked );
						}, 16 );

						if ( id ) {
							const others = items.filter( ( other ) => {
								return other.getAttribute( 'id' ) !== id;
							} );

							// Update other items' ARIA roles as well [1]
							this.hideOthers( others );
						}
					}, trigger );
				} );
			}
		}

		// [1]
		hideOthers( others ) {
			if ( others.length ) {
				others.forEach( ( other ) => {
					const content = other.querySelector( '.js-accordion-content' );
					if ( content ) {
						content.setAttribute( 'aria-hidden', 'true' );
					}

					const trigger = other.querySelector( '.js-accordion-trigger' );
					if ( trigger ) {
						trigger.setAttribute( 'aria-expanded', 'false' );
					}
				} );
			}
		}
	},
} );
