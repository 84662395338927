/*
 * Mr Info Video 🔍
 *
 * Initiates a video player, using either a local file, external
 * file (URL), or embedded video.
 *
 * The first two will use mr-video, the third one uses the Vimeo Embed Player.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-info-video', {
	attributes: [
		{
			attribute: 'video-id',
			type: 'string',
		},
		{
			attribute: 'swap-src',
			type: 'string',
		},
		{
			attribute: 'swap-type',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.button = this.el.querySelector( '.js-info-video-swap-button' );
			this.elements.close = this.el.querySelector( '.js-info-video-close' );
			this.elements.container = this.el.querySelector( '.js-info-video-container' );

			this.swapped = false;
		}

		bind() {
			this.on( 'click', () => {
				// Do nothing if no swapping is needed/available
				if ( !this.swapType ) {
					return;
				}

				// Add loading state to button and initiate swapping
				this.elements.button.classList.add( 'is-loading' );
				this.swap( this.swapType );
			}, this.elements.button );

			this.on( 'click', () => {
				// Exit the 'zoomed video' state
				window.requestAnimationFrame( () => {
					this.el.classList.remove( 'is-playing-video' );
				} );

				// Reset the 'watch full video' button
				if ( this.elements.button ) {
					this.elements.button.classList.remove( 'is-hidden' );

					setTimeout( () => {
						this.elements.button.classList.remove( 'has-loaded' );
					}, 1120 );
				}
			}, this.elements.close );
		}

		swap( type ) {
			// If we know what to swap, fade out the container
			if ( ( 'embed' === type || 'src' === type ) && !this.swapped ) {
				this.elements.container.classList.add( 'is-faded' );
			}

			if ( 'embed' === type ) {
				// Check if all needed elements are there
				const iframe = this.el.querySelector( '.js-info-video-iframe' );
				const videoPlayer = this.el.querySelector( '.js-info-video-player' );

				if ( !this.elements.container || !iframe ) {
					return;
				}

				// Wait for container fade out
				setTimeout( () => {
					// Only do this if the video isn't already swapped
					if ( !this.swapped ) {
						// If there's a video preview, remove it
						if ( videoPlayer ) {
							this.elements.container.removeChild( videoPlayer );
						}

						// Add the iframe and remove 'display: none;'
						this.elements.container.appendChild( iframe );
						iframe.style.cssText = '';

						this.swapped = true;
					}

					// Finish up button and container styles etc
					this.finishUp();
				}, 160 );

			} else if ( 'src' === type ) {
				// Check if all needed elements are there
				const video = this.el.querySelector( '.js-video' );

				if ( !this.elements.container || !this.swapSrc || '' === this.swapSrc || !video ) {
					return;
				}

				// Wait for container fade out
				setTimeout( () => {
					// Only do this if the video isn't already swapped
					if ( !this.swapped ) {
						// Swap (or enter) the video source
						video.src = this.swapSrc;

						this.swapped = true;
					}

					// Finish up button and container styles etc
					this.finishUp();
				}, 160 );
			}
		}

		finishUp() {
			/*
			 * When swapping use a small timeout to make it clear the video
			 * has loaded, even if it's all done really fast.
			 */

			let delay = 400;
			if ( this.swapped ) {
				delay = 0;
			}

			// Update the button styles and fade away
			if ( this.elements.button ) {
				setTimeout( () => {
					this.elements.button.classList.add( 'has-loaded' );

					setTimeout( () => {
						this.elements.button.classList.add( 'is-hidden' );
						this.elements.button.classList.remove( 'is-loading' );
					}, 160 );
				}, delay );
			}


			// If we need controls, we can show 'em now
			const controls = this.el.querySelector( '.js-video-controls' );

			if ( controls ) {
				controls.classList.remove( 'is-hidden' );
				controls.classList.remove( 'is-showing' );
			}

			// When using mr-video, let him know we've swapped the source
			if ( this.videoId && 'src' === this.swapType ) {
				this.el.dispatchEvent( new CustomEvent( 'mr-info-video:swapped', {
					bubbles: true,
					cancelable: true,
					detail: {
						videoId: this.videoId,
					},
				} ) );
			}

			// Fade in the container again top show the new video
			if ( this.elements.container ) {
				this.elements.container.classList.remove( 'is-faded' );
			}

			// Update the element to enter the 'zoom video' state
			window.requestAnimationFrame( () => {
				this.el.classList.add( 'is-playing-video' );
			} );
		}
	},
} );
