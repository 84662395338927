class MrTransparentVideo extends HTMLElement {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		const video = document.createElement( 'video' );
		if ( this.hasAttribute( 'data-webm-src' ) && 'probably' === video.canPlayType( 'video/webm; codecs="vp9"' ) ) {
			this.loadWebM();

			return;
		}

		this.loadGif();
	}

	loadGif() {
		if ( !this.hasAttribute( 'data-gif-src' ) ) {
			return;
		}

		const img = document.createElement( 'img' );
		this.appendChild( img );
		img.src = this.getAttribute( 'data-gif-src' );
	}

	loadWebM() {
		const video = document.createElement( 'video' );
		video.autoplay = true;
		video.playsinline = true;
		video.muted = true;
		video.loop = true;
		video.preload = 'auto';
		this.appendChild( video );
		video.src = this.getAttribute( 'data-webm-src' );
	}
}

customElements.define( 'mr-transparent-video', MrTransparentVideo );
