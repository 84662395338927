/*
 * Mr Form 📯
 *
 * This element updates the value of form inputs on change.
 * This way css can pick up on fields with entered values,
 * and show it's label. The initial state only shows a placeholder,
 * which disappears after the user enters a value.
 * This way we avoid possible confusion about which field is which.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-form', {
	attributes: [],
	controller: class extends BaseController {
		bind() {
			const inputs = Array.from( this.el.querySelectorAll( '.js-form-input' ) );

			if ( inputs.length ) {
				inputs.forEach( ( input ) => {
					this.on( 'change', () => {
						input.setAttribute( 'value', input.value );
					}, input );
				} );
			}
		}
	},
} );
